import React from 'react'
import { targetEnv } from '@fs/zion-config'
import { Switch, Route, NotFound, AuthRoute } from '@fs/zion-router'
import ErrorBoundary from '@fs/zion-error-boundary'
import { BrightspotRouting } from '@fs/zion-cms'
import { useFeatureFlag } from '@fs/zion-flags'
import {
  oneSearchDiscoveryBase,
  oneSearchDiscoveryResults,
  allCollections,
  oneSearchV2Results,
  recentSearches,
  surnameBase,
  surnamesBase,
  surnameDirectory,
} from './paths'

// Dynamically load components to reduce bundle size
// https://reactjs.org/docs/react-api.html#reactlazy

const SearchPage = React.lazy(() => import('./SearchPage'))
const SearchPageAll = React.lazy(() => import('./SearchPage/SearchPageAll'))
const SearchPageAllCollections = React.lazy(() => import('./SearchPageAllCollections'))
const ResultsPage = React.lazy(() => import('./ResultsPage'))
const ResultsPageV2 = React.lazy(() => import('./ResultsPageV2'))
const RecentSearches = React.lazy(() => import('./RecentSearches'))
const SurnameExperience = React.lazy(() => import('./components/SurnamePage/SurnameExperience'))
const SurnameDirectory = React.lazy(() => import('./components/SurnamePage/SurnameDirectory'))
const SharedSurnamesExperience = React.lazy(() => import('./pages/SharedSurnamesExperience/SharedSurnamesExperience'))

const localPathMod = (path) => {
  /**
   * if our path has a bit in it that matches a locale then the language picker will work locally.
   */
  return targetEnv === 'local' ? [`/:lang${path}`, path] : path
}

function App() {
  const showNewOneSearchLandingPage = useFeatureFlag('one_search_landingPage')?.isOn || false
  const OneSearchLandingPage = showNewOneSearchLandingPage ? SearchPageAllCollections : SearchPageAll
  return (
    <ErrorBoundary>
      <Switch>
        {/**
         * DISCOVERY - Routes
         */}
        <Route path={localPathMod(oneSearchDiscoveryBase)} component={SearchPage} exact />
        <Route path={localPathMod(oneSearchDiscoveryResults)} component={ResultsPage} exact />

        {/**
         * ONE SEARCH v2 - Routes
         */}
        {showNewOneSearchLandingPage ? (
          <AuthRoute path={localPathMod(allCollections)} component={OneSearchLandingPage} exact />
        ) : (
          <Route path={localPathMod(allCollections)} component={OneSearchLandingPage} exact />
        )}
        <Route path={localPathMod(recentSearches)} component={RecentSearches} />
        <AuthRoute path={localPathMod(oneSearchV2Results)} component={ResultsPageV2} />

        {/**
         * SURNAME / SURNAMES - Routes
         */}

        {/**
         * We need to be able to include the locale for /surname because of its routing in server.js.
         */}
        <Route path={localPathMod(surnameBase)} component={SurnameExperience} exact />
        <Route path={`${surnameDirectory}/:name`} component={SurnameDirectory} exact />
        <Route path={localPathMod(surnamesBase)} component={SharedSurnamesExperience} />
        <Route path="/surname/cms" render={() => <BrightspotRouting site="surname" />} />

        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  )
}
export default App

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/browser'
import { setAppLocales, essentialLocales } from '@fs/zion-locale'
import Root from '@fs/zion-root'
import { AppLayout } from '@fs/zion-ui'
import { Router } from '@fs/zion-router'
import { appPath, sentryDSN, mergeSentryConfig } from '@fs/zion-config'

import { FeatureFlagsProvider } from '@fs/zion-flags'
import { NoticeLoading } from '@fs/zion-icon'
import { BrightspotProvider } from '@fs/zion-cms'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './locales'
import AdobeTargetProvider from './AdobeTargetProvider'

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      ignoreErrors: ['by request of the user', 'Network Error', 'Failed to fetch', 'SyntaxError'],
      dsn: sentryDSN,
    })
  )
}

setAppLocales(essentialLocales)

// This is where you pass data from the server to the client using the SERVER_DATA global.
// Here we pass the mounted app path from the server to the client.
const base = appPath ? new URL(appPath).pathname : ''

const FrontierRoot = () => {
  const useFatFooter = window.location.pathname.includes('surname') && !window.location.pathname.includes('directory')
  return (
    <React.StrictMode>
      <BrightspotProvider sites={['surname']} hasCustomContent>
        <Suspense fallback={<NoticeLoading />}>
          <FeatureFlagsProvider mockFlags={mockFlags}>
            <Router basename={base}>
              <Root analytics footer={{ useFatFooter }}>
                <AdobeTargetProvider>
                  <AppLayout fullWidth>
                    <App />
                  </AppLayout>
                </AdobeTargetProvider>
              </Root>
            </Router>
          </FeatureFlagsProvider>
        </Suspense>
      </BrightspotProvider>
    </React.StrictMode>
  )
}
ReactDOM.render(<FrontierRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
